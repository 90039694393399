@tailwind base;
@tailwind components;
@tailwind utilities;



/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
  }
  /* styles.css */
.swal-container {
  z-index: 9999 !important; /* Adjust the z-index value as needed */
}

  
  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }